import JSZip, { InputFileFormat, JSZipObject } from '@progress/jszip-esm';
import { AssetType, IDicomMetadata, caseUtils } from '@workflow-nx/common';
import { date } from '@workflow-nx/utils';
import dicomParser, { ByteArray } from 'dicom-parser';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { TAG_DICT } from './tag-dictionary';

function isASCII(value: string) {
  return /^[\x00-\x7F]*$/.test(value);
}

function isStringVr(vr: string) {
  return !(
    vr === 'AT' ||
    vr === 'FL' ||
    vr === 'FD' ||
    vr === 'OB' ||
    vr === 'OF' ||
    vr === 'OW' ||
    vr === 'SI' ||
    vr === 'SQ' ||
    vr === 'SS' ||
    vr === 'UL' ||
    vr === 'US'
  );
}

// function adapted from https://github.com/cornerstonejs/dicomParser/tree/master/examples/dumpWithDataDictionary
function dumpDataSet(dataSet: any): { [key: string]: string } {
  const output: { [key: string]: string } = {};

  function getTag(tag: string) {
    const group = tag.substring(1, 5);
    const element = tag.substring(5, 9);
    const tagIndex = ('(' + group + ',' + element + ')').toUpperCase();

    return TAG_DICT[tagIndex];
  }

  const keys = [];
  for (const propertyName in dataSet.elements) {
    keys.push(propertyName);
  }
  keys.sort();

  for (const propertyName of keys) {
    const element = dataSet.elements[propertyName];
    let text = '';
    let key = '';
    const tag = getTag(element.tag);

    if (tag === undefined) {
      key = element.tag;
    } else {
      key = tag.name;
    }

    if (!element.fragments) {
      // use VR to display the right value
      let vr;
      if (element.vr !== undefined) {
        vr = element.vr;
      } else {
        if (tag !== undefined) {
          vr = tag.vr;
        }
      }

      if (element.vr === undefined && tag === undefined) {
        if (element.length === 2) {
          text += ' (' + dataSet.uint16(propertyName) + ')';
        } else if (element.length === 4) {
          text += ' (' + dataSet.uint32(propertyName) + ')';
        }

        const str = dataSet.string(propertyName);
        const stringIsAscii = isASCII(str);
        if (stringIsAscii) {
          if (str !== undefined) {
            text += str;
          }
        }
      } else {
        if (isStringVr(vr)) {
          const str = dataSet.string(propertyName);
          const stringIsAscii = isASCII(str);
          if (stringIsAscii) {
            if (str !== undefined) {
              text += str;
            }
          }
        } else if (vr === 'US') {
          text = dataSet.uint16(propertyName);
        } else if (vr === 'SS') {
          text = dataSet.int16(propertyName);
        } else if (vr === 'UL') {
          text = dataSet.uint32(propertyName);
        } else if (vr === 'SL') {
          text = dataSet.int32(propertyName);
        } else if (vr === 'FD') {
          text = dataSet.double(propertyName);
        } else if (vr === 'FL') {
          text = dataSet.float(propertyName);
        }
      }

      output[key] = text;
    }
  }

  return output;
}

export function getDicomBufferMetadata(byteArray: Uint8Array): IDicomMetadata {
  const dataSet = dicomParser.parseDicom(byteArray);
  const metadata = dumpDataSet(dataSet);

  const mrn = metadata['PatientID'];
  const patientName = metadata['PatientName'];
  const patientBirthDate = metadata['PatientBirthDate'];
  const patientSex = metadata['PatientSex'];
  const studyDate = metadata['StudyDate'];
  const imageType = metadata['ImageType'];
  const protocolName = metadata['ProtocolName'];
  const institutionName = metadata['InstitutionName'];
  const referringPhysician = metadata['ReferringPhysicianName'];
  const manufacturer = metadata['Manufacturer'];
  const gantryDetectorTilt = metadata['GantryDetectorTilt'];
  const pixelSpacingStr = metadata['PixelSpacing'] ?? metadata['ImagerPixelSpacing'];
  let pixelSpacing: number | undefined = undefined;

  if (pixelSpacingStr) {
    const pixelSpacingVal = pixelSpacingStr.split('\\')[0];

    pixelSpacing = pixelSpacingVal ? Number(pixelSpacingVal) : undefined;
  }

  let sliceThickness;
  if (imageType?.toLowerCase().includes('axial')) {
    sliceThickness = metadata['SliceThickness'];
  }

  const [lastName, firstName, middleName] = (patientName || '').split('^');

  const birthDate = patientBirthDate
    ? DateTime.fromFormat(patientBirthDate, 'yyyyMMdd').toISODate()
    : '';
  const parsedStudyDate = studyDate ? DateTime.fromFormat(studyDate, 'yyyyMMdd').toISODate() : '';

  const dicomMetadata: IDicomMetadata = { hasSliceThicknessError: false, hasStudyDateError: false };

  dicomMetadata.firstName = firstName || '';
  dicomMetadata.lastName = lastName || '';
  dicomMetadata.middleName = middleName || '';

  dicomMetadata.mrn = mrn || '';
  if (patientSex) {
    dicomMetadata.gender = patientSex.trim() === 'F' ? 'FEMALE' : 'MALE';
  }
  dicomMetadata.birthDate = birthDate;
  dicomMetadata.studyDate = parsedStudyDate;
  dicomMetadata.sliceThickness = sliceThickness;
  dicomMetadata.protocolName = protocolName || '';
  dicomMetadata.institutionName = institutionName || '';
  dicomMetadata.referringPhysician = referringPhysician || '';
  dicomMetadata.manufacturer = manufacturer || '';
  dicomMetadata.gantryDetectorTilt = gantryDetectorTilt || '';
  dicomMetadata.pixelSpacing = pixelSpacing;

  return dicomMetadata;
}

export const getFileMetadata = async (zipFile: JSZipObject): Promise<IDicomMetadata | null> => {
  let metadata: IDicomMetadata = { hasSliceThicknessError: false, hasStudyDateError: false };

  const data = await zipFile.async('uint8array');

  const byteArray = new Uint8Array(data);

  try {
    metadata = getDicomBufferMetadata(byteArray);
  } catch (e) {
    console.error('Error occurred when parsing metadata for', zipFile.name, e);
  }

  return metadata;
};

export const getDicomMetadata = (
  zipFile: InputFileFormat,
  assetType?: AssetType,
  caseReceivedAt?: Date | string,
): Promise<IDicomMetadata> => {
  let metadata: IDicomMetadata = { hasSliceThicknessError: false, hasStudyDateError: false };

  return new Promise<IDicomMetadata>(async (resolve, reject) => {
    const zip = await new JSZip().loadAsync(zipFile);
    if (zip === null) {
      return reject(new Error('Cannot load zip file'));
    } else {
      for (const path in zip.files) {
        const zipFile = zip.file(path);
        if (!!zipFile && !zipFile.dir) {
          const fileMetadata = await getFileMetadata(zipFile);

          if (fileMetadata) metadata = _.merge(metadata, fileMetadata);

          if (
            metadata.studyDate &&
            metadata.firstName &&
            metadata.lastName /*&&
            metadata.birthDate &&
            metadata.sliceThickness &&
            metadata.gender &&
            metadata.mrn*/
          ) {
            break;
          }
        }
      }
      const expiryDate = metadata.studyDate
        ? caseUtils.getExpiryDateFromStudyDate(metadata.studyDate, caseReceivedAt, assetType)
        : undefined;

      metadata.hasStudyDateError = Boolean(
        !!metadata?.studyDate && expiryDate && date.isBeforeToday(expiryDate),
      );

      metadata.hasSliceThicknessError =
        !!metadata?.sliceThickness && Number(metadata?.sliceThickness) > 1;
      return resolve(metadata);
    }
  });
};

export const PARSABLE_DICOM_TYPES: AssetType[] = [
  AssetType.DicomCt,
  AssetType.DicomXray,
  AssetType.PostOpIntraOpXray,
  AssetType.PostOpOneWeekXray,
  AssetType.PostOpSixWeeksXray,
  AssetType.PostOpSixMonthsXray,
  AssetType.PostOpOneYearXray,
  AssetType.PostOpTwoYearXray,
  AssetType.PostOpIntraOp,
  AssetType.PostOpIntraOpCt,
  AssetType.PostOpOneWeekCt,
  AssetType.PostOpSixWeeksCt,
  AssetType.PostOpSixMonthsCt,
  AssetType.PostOpOneYearCt,
  AssetType.PostOpTwoYearCt,
  AssetType.PostOpIntraOpMri,
  AssetType.PostOpOneWeekMri,
  AssetType.PostOpSixWeeksMri,
  AssetType.PostOpSixMonthsMri,
  AssetType.PostOpOneYearMri,
  AssetType.PostOpTwoYearMri,
];
